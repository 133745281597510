.App {
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;
}

.App-logo {
    margin-top: 10px;
    height: 10vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-text {
    font-size: 18px;
    color: white;
}

.App-link {
    color: #61dafb;
}

.App-footer {
    text-align: center;
    margin: auto;
    font-size: 16px;
}

.Section-title {
    text-align: center;
    color: orange;
}

.Section-body {
    text-align: justify;
    margin: auto;
    font-size: 20px;
    color: gray;
}

.Tab-Section-body {
    text-align: justify;
    margin: auto;
    font-size: 20px;
    color: gray;
}

.Tab-Section-body-purple {
    text-align: justify;
    margin: auto;
    font-weight: bold;
    font-size: 20px;
    color: purple;
}

.Home-Image {
    width: 1042px;
    height: 720px;
    vertical-align: middle;
}

.Features-table-header {
    background-color: #CADFF4;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    color: gray;
}

.Features-table-body {
    background-color: #FAFCFE;
    font-size: 20px;
    text-align: left;
    height: 50px;
    color: gray;
}

.Features-new-table-header {
    background-color: #CADFF4;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    color: orange;
}

.Features-new-table-body {
    background-color: #FAFCFE;
    font-size: 20px;
    text-align: left;
    height: 50px;
    color: orange;
}

.Download-table-header {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    color: blue;
}

.Screencuts-label {
    color: gray;
    font-size: 18px;
}

.Licensing-icon {
    text-align: left;
}

.Purchase-header {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    width: 620px;
    color: #F08080;
}

.Purchase-selection {
    text-align: left;
    width: 420px;
}

.Purchase-paypal-note {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    color: blue;
    margin-left: 10px;
}

.Purchase-body {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    color: #F08080;
}

.Customers-Image {
    width: 288px;
    height: 72px;
    vertical-align: middle;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
